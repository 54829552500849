import React, { useEffect, useState } from 'react';
import { CommonBackContent } from '../Layouts/CommonBackContent';
import { useConfigProvider } from '@/context/ConfigProvider';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
// import { Affix, Button, Modal } from 'antd';
// import Image from 'next/image';
// import { useTranslation } from 'next-i18next';
// import { CloseOutlined } from '@ant-design/icons';
// import { useToggle } from 'react-use';
import { HomeUpdateEmailKey, PageType } from '@/shared/app-common';
import { useUserProvider } from '@/context/UserProvider';
import { getUserInfo, updateNotifationEmail } from '@/lib/service';
import { isEmpty } from 'lodash-es';
import { isApplePrivateEmail, isEmailValid } from '@/shared/utils';
import CommonSimpleModal from '../Common/CommonSimpleModal';
import { Input, message } from 'antd';
import { useTranslation } from 'next-i18next';
import { UserInfo } from '@/types/home';

const LoginStateInfo = dynamic(() => import('./LoginStateInfo'), { ssr: true });
const DetailShowContent = dynamic(() => import('./DetailShowContent'), { ssr: true });
const FeaturesContent = dynamic(() => import('./FeaturesContent'), { ssr: true });
const UseShowContent = dynamic(() => import('./UseShowContent'), { ssr: true });
const CustomersTalkUsContent = dynamic(() => import('./CustomersTalkUsContent'), { ssr: true });
const RecommendedArticleContent = dynamic(() => import('./RecommendedArticleContent'), {
  ssr: true,
});
const RegisterContent = dynamic(() => import('./RegisterContent'), { ssr: true });
// const HomeAIContent = dynamic(() => import('./HomeAIContent'), { ssr: true });
const LandingPageIntroduce = dynamic(() => import('./LandingPageIntroduce'), { ssr: true });

const MemoCommonBackContent = React.memo(CommonBackContent);

const HomeContent = ({ data }: { data: any }) => {
  const { isBlogWeb } = useConfigProvider();
  const { isLogin } = useUserProvider();
  const router = useRouter();
  // const { t } = useTranslation('common');
  // const [showNewFeature, setShowNewFeature] = useToggle(true);
  // const [open, setOpen] = useToggle(false);
  const topInfo = React.useRef<HTMLDivElement>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [showEditNotiEmail, setShowEditNotiEmail] = useState<boolean>(false);
  const [receiveEmail, setReceiveEmail] = useState<string>(null);
  const [uerInfo, setUserInfo] = useState<UserInfo>(null);
  const { t } = useTranslation();

  const pageType = data.pageType;
  useEffect(() => {
    if (isBlogWeb) {
      router.push('/instagram-tracking-blog');
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') { // 确保在浏览器环境中
      if (isLogin && localStorage.getItem(HomeUpdateEmailKey) !== "showed") {
        getUserInfo()
          .then((res) => {
            if (res?.code === 0) {
              if (res?.data?.receive_email?.length > 0 && isApplePrivateEmail(res?.data?.receive_email) || isApplePrivateEmail(res?.data?.email)) {
                setShowEditNotiEmail(true);
                setUserInfo(res?.data);
              }
              localStorage.setItem(HomeUpdateEmailKey, "showed");
            }
          }).catch((e) => { });
      }
    }
  }, [isLogin]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        setIsIntersecting(entry.isIntersecting)
      });
    });

    if (topInfo.current) {
      observer.observe(topInfo.current);
    }

    return () => {
      if (topInfo.current) {
        observer.unobserve(topInfo.current);
      }
    };
  }, []);

  // const rightAlertContainer = useMemo(() => {
  //   return (
  //     <div
  //       style={{
  //         float: 'right',
  //         marginRight: '20px',
  //         textAlign: 'center',
  //         cursor: 'pointer',
  //         display: showNewFeature ? 'block' : 'none',
  //       }}
  //       onClick={() => {
  //         setOpen(true);
  //       }}
  //     >
  //       <CloseOutlined
  //         style={{
  //           background: '#FFF',
  //           float: 'right',
  //           padding: '5px',
  //           borderRadius: '50%',
  //         }}
  //         onClick={(e) => {
  //           e?.stopPropagation();
  //           setShowNewFeature(false);
  //         }}
  //       />
  //       <Image src={'/images/home/home_new_icon.webp'} width={50} height={50} alt={'new_icon'} />
  //       <div
  //         style={{
  //           fontWeight: '500',
  //           textShadow: '1px 1px 0 white, -1px -1px 0 white, -1px 1px 0 white, 1px -1px 0 white',
  //         }}
  //       >
  //         {t('New Feature')}
  //       </div>
  //     </div>
  //   );
  // }, [showNewFeature, setOpen, setShowNewFeature]);

  // const modalContent = useMemo(() => {
  //   return (
  //     <Modal
  //       className={'.commonModalContainer'}
  //       closeIcon={<CloseOutlined style={{ color: '#FFF' }} />}
  //       open={open}
  //       onCancel={() => setOpen(false)}
  //       destroyOnClose={true}
  //       footer={null}
  //     >
  //       <div
  //         style={{
  //           color: '#FFF',
  //           padding: '20px 24px',
  //           backgroundImage: `url('/images/home/home-new-feature-bg.webp')`,
  //           borderRadius: '8px',
  //         }}
  //       >
  //         <div
  //           style={{
  //             fontSize: '20px',
  //             fontWeight: 'bold',
  //             marginBottom: '18px',
  //           }}
  //         >
  //           {t('New Feature Alert!')}
  //         </div>
  //         <p
  //           style={{
  //             marginBottom: '18px',
  //           }}
  //         >
  //           {t(
  //             'Exciting news! DolphinRadar now offers AI Insights, providing you with deeper, more actionable understanding of the accounts you track.',
  //           )}
  //         </p>
  //         <Button
  //           style={{
  //             height: '40px',
  //             borderRadius: '20px',
  //             color: 'white',
  //             background: `linear-gradient( 180deg, #725CFF 0%, #8D83FF 100%)`,
  //             border: 'none',
  //             cursor: 'pointer',
  //             fontSize: '14px',
  //           }}
  //           onClick={() => {
  //             router.push('/analytics/1705045472413474315?name=kyliejenner&isDemo=true&dataType=1');
  //           }}
  //         >
  //           {t('Explore AI Insights Now')}
  //         </Button>
  //       </div>
  //     </Modal>
  //   );
  // }, [open]);

  const onConfirmReceiveEmail = () => {
    updateNotifationEmail(receiveEmail).then((res) => {
      if (res.code === 0) {
        message.success(t('Email for notifications updated successfully!'));
        setReceiveEmail(null);
      } else {
        message.error(res?.message);
      }
    }).catch((error) => {
      message.error(error?.message);
    })
  }

  return (
    <>
      {isBlogWeb ? (
        <></>
      ) : (
        <div>
          <MemoCommonBackContent>
            <div ref={topInfo}>
              <LoginStateInfo pageType={pageType} />
            </div>
            {pageType === PageType.Normal && <DetailShowContent />}
            {/* <HomeAIContent /> */}
            {pageType !== PageType.Normal && <LandingPageIntroduce pageType={pageType} />}
            <FeaturesContent />
            <UseShowContent />
          </MemoCommonBackContent>

          {data?.articleList?.length > 0 && <RecommendedArticleContent dataList={data?.articleList} />}
          <CustomersTalkUsContent />

          <MemoCommonBackContent>
            <RegisterContent isIntersecting={isIntersecting} />
          </MemoCommonBackContent>

          {/* <Affix offsetBottom={220}>{rightAlertContainer}</Affix> */}

          {/* {modalContent} */}
          {showEditNotiEmail && <CommonSimpleModal
            open={showEditNotiEmail}
            title={t('Notice')}
            desc={<div>
              <p>{t(`No email linked. Bind an email to get updates. You can skip now and add it later in`)}{` `}<a style={{ color: `#725CFF` }} href={'/settings'}>{t("settings")}</a>.</p>
              <Input
                placeholder={t("Email")}
                style={{ marginTop: '20px', marginBottom: '30px' }}
                onChange={(e) => setReceiveEmail(e?.target?.value)}
              />
            </div>}
            closeBtnTitle={t('Skip for now')}
            btnTitle={t('Confirm')}
            closeAction={(isOk: boolean) => {
              if (isOk) {
                if (!isEmailValid(receiveEmail)) {
                  return message.error(t('Please enter a valid email address'));
                }
                onConfirmReceiveEmail();
              }
              setShowEditNotiEmail(false);
            }}
          />}
        </div>
      )}
    </>
  );
};

export default HomeContent;
